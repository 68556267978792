<template>
  <div id="app">
     <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
}
#app {
  max-width: 2050px;
  margin: 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* color: #2c3e50; */

}
</style>
