import Vue from 'vue'
import App from './App.vue'
import store from './store/index';
import router from './router/index' // 引入路由
import animated from 'animate.css' // npm install animate.css --save安装，在引入

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
 
Vue.use(ElementUI);
Vue.use(animated)

import VueAwesomeSwiper from 'vue-awesome-swiper'     //引入swiper
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)


import './common/css/font.css'
Vue.config.productionTip = false
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);
router.beforeEach((to,from,next)=>{
  store.state.currPage=to.name
  console.log( store.state.currPage);

  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
