import Vue from 'vue'
import VueRouter from 'vue-router'
// import index from '../views/index.vue'

// import PersonalCenter from '../components/center/personalCenter.vue'
Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const routes = [
 
  {
   
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "Search" */'../views/index.vue')
  },
  {
    path: '/coremanment',
    name: 'coremanment',
    component: () => import(/* webpackChunkName: "Search" */'../views/coremanment.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "Login" */'../views/download.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/aboutUs.vue')
  },
  {
    // 祖国
    path: '/motherland',
    name: 'motherland',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/motherland.vue')
  },{
    path: '/positioninfo',
    name: 'positioninfo',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/positioninfo.vue')
  },{
    path: '/employeegrowth',
    name: 'employeegrowth',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/employeegrowth.vue')
  },{
    path: '/heatedvest',
    name: 'heatedvest',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/heatedvest.vue')
  },{
    path: '/mentalres',
    name: 'mentalres',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/mentalres.vue')
  },{
    path: '/familyhapp',
    name: 'familyhapp',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/familyhapp.vue')
  },
  {
    path: '/subordinate',
    name: 'subordinate',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/subordinate.vue')
  },
  {
    path: '/founderstory',
    name: 'founderstory',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/founderstory.vue')
  },
  {
    path: '/custorep',
    name: 'custorep',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/custorep.vue')
  },
  {
    path: '/specialist',
    name: 'specialist',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/specialist.vue')
  },
  {
    path: '/finmanager',
    name: 'finmanager',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/finmanager.vue')
  },
  {
    path: '/weiyiwang',
    name: 'weiyiwang',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/weiyiwang.vue')
  },
  {
    path: '/newenergy',
    name: 'newenergy',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/newenergy.vue')
  },
  {
    path: '/hongqipower',
    name: 'hongqipower',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/hongqipower.vue')
  },
  {
    path: '/callus',
    name: 'callus',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/callus.vue')
  },
  {
    path: '/supplychain',
    name: 'supplychain',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/supplychain.vue')
  },
  {
    path: '/qiyejs',
    name: 'qiyejs',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/qiyejs.vue')
  },
  {
    path: '/weiyiw',
    name: 'weiyiw',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/weiyiw.vue')
  },
  {
    path: '/sahnpinye',
    name: 'sahnpinye',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/sahnpinye.vue')
  },
  {
    path: '/wanhong',
    name: 'wanhong',
    component: () => import(/* webpackChunkName: "iframeBack" */'../views/wanhong.vue')
  },
  // {
   
  //   path: '*',
  //   name: '404',
  //   component: () => import('../views/404.vue')
  // },
 
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 路由守卫
router.beforeEach((to,from,next)=>{
  // store.state.currPage=to.name
  next();
  // alert(to.name)
  // if(to.path=="/"){
  //   next();
  // }else{
  //  this.$router.push(to.name)  
  // }

  
 
})

export default router
